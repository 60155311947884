// CSS Style
import 'admin/styles/site.scss'
import 'admin/styles/style.min.css'
import 'admin/styles/custom.scss'
import 'styles/pos'

// Other JS
import('admin/site')
import 'admin/notification'
import 'pos/base'

import Rails from "@rails/ujs"

Rails.start();

window.jQuery = $;
window.$ = $;
