import 'bootstrap'
import 'bootstrap-select'
import 'admin/notification'

// Some JavaScript Here
$(document).ready(function(){
  $('.drop').click(function(){
    $('.dropdown-menu').toggle();
  })

  $('#order_credit_card').on("input", function(){
    let cash = $('#order_total').val() - $('#order_discount').val() - $(this).val()
    $('#cash').html('$ ' + cash.toLocaleString())
  })
 })

